import { connect } from 'react-redux';
import Frontdesk from './frontdesk';
import { fetchAppointments, fetchAppointments2 } from '../../actions/user_actions'

const mapStateToProps = (state) => {
  return {
    currentUser: state.session.user,
    dailyAppointments: state.user.appointments,
    monthlyAppointments: state.user.monthlyAppointments
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
    fetchAppointments: (calendarID, minDate, maxDate) => dispatch(fetchAppointments(calendarID, minDate, maxDate)),
    fetchAppointments2: (calendarID, minDate, maxDate) => dispatch(fetchAppointments2(calendarID, minDate, maxDate))
    }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Frontdesk);