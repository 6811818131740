import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import Footer from './footer';
import CourseNav from './course_nav_container';
import { Ring } from 'react-spinners-css';
import Logo1 from '../../assets/costranslogo.png'
import Logo2 from '../../assets/costranslogo2.png'
import BookingPage from './booking_page_container';

export default function Frontdesk(props) {

    const {fetchAppointments, currentUser, dailyAppointments, fetchAppointments2, monthlyAppointments} = props
    const [result, setResult] = useState(null)
    const [upcoming, setUpcoming] = useState(null)
    const [dateObject, setDateObject] = useState(moment())
    const [year, setYear] = useState(null)
    const [month, setMonth] = useState(null)
    const [currentDay, setCurrentDay] = useState(null)
    const [selectedFullDate, setSelectedFullDate] = useState(null)
    const [dateArray, setDateArray] = useState(null)
    const [monthDigit, setMonthDigit] = useState(null)
    const [minMaxDate, setMinMaxDate] = useState(null)
    const [loading, setLoading] = useState(false)
    const [writtenDate, setWrittenDate] = useState(null)
    const [writtenMonth, setWrittenMonth] = useState(null)
    const [yearsOpen, setYearsOpen] = useState(false)
    const [monthsOpen, setMonthsOpen] = useState(false)
    const [yearTime, setYearTime] = useState(null)
    const [minMaxDate2, setMinMaxDate2] = useState(null)
    const [datesList, setDatesList] = useState(null)
    const [showCalendar, setShowCalendar] = useState(true) 
    const [bookingPage, setBookingPage] = useState(false)
    const [chosenClient, setChosenClient] = useState(null)

    const date = new Date()
    const today = `${date.getFullYear()}`+`-`+`${date.getMonth() + 1}`+`-`+`${date.getDate()}`

    const months = [`Jan`, `Feb`, `Mar`, `Apr`, `May`, `Jun`, `Jul`, `Aug`, `Sep`, `Oct`, `Nov`, `Dec`]
    const monthsFull = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const artists = ["Nikky", "Sean", "Karlee", "Val", "STUDENTS"]
    
    function setCalendar() {
        showCalendar ? setShowCalendar(false) : setShowCalendar(true)
    }

    function openBookingPage(client) {
        bookingPage ? setBookingPage(client) : setBookingPageOpen(client)
    }

    function setBookingPageOpen(client) {
        setBookingPage(true)
        setChosenClient(client)
    }


    let weekdayshort = moment.weekdaysShort();
    let weekdayshortname = weekdayshort.map(day => {
        return (
          <th key={day} className="week-day">
           {day}
          </th>
        );
     });

   function getAppointments() {
        fetchAppointments(null, minMaxDate, minMaxDate)
    }

    function getAppointments2() {
        fetchAppointments2(null, `${year}`+`-`+`${month}`+`-`+`${1}`, `${year}`+`-`+`${month}`+`-`+`${31}`)
  }



     useEffect(() => {
       if (selectedFullDate) {
        setDateArray(selectedFullDate.split(", ")) 
       }
    }, [selectedFullDate])

    useEffect(() => {
        if (!currentDay) {
          setSelectedDate(date.getDate())
        }
     }, [currentDay])

    useEffect(() => {
        if (dateArray) {
   
        const m = dateArray[1].split(" ")[0]
        const y = dateArray[2].split(" ")[0]
         setMonth(m)
         setYear(y)
        }
     }, [dateArray])

     useEffect(() => {
        window.scrollTo(0, 0)
     }, [])


     useEffect(() => {
        if (month) {
        const md = months.indexOf(month)
         setMonthDigit(md +1)
        }
     }, [month])

     useEffect(() => {
        if (year) {
        setYearTime(year)
        }
     }, [year])


     useEffect(() => {
        if (currentDay && monthDigit) {
        setMinMaxDate(`${year}` + `-` + `${monthDigit}`+ `-` + `${currentDay}`)
        }
     }, [currentDay, monthDigit])

     useEffect(() => {
        if (yearTime) {
        setMinMaxDate(`${yearTime}` + `-` + `${monthDigit}`+ `-` + `${currentDay}`)
        }
     }, [yearTime])

     useEffect(() => {
        if (minMaxDate) {

                getAppointments()   
                getAppointments2()
        }
     }, [minMaxDate])


     useEffect(() => {
        if (dailyAppointments) {
            setResult(dailyAppointments.reverse())
           setTimeout(() => {
               setLoading(false)
           }, 300);
        }
     }, [dailyAppointments])

     useEffect(() => {
        if (monthlyAppointments) {
            var datesList = []
            monthlyAppointments.forEach((appointment, i) => {
              if (appointment.date.split(" ")[0].includes(month)) {
                datesList.push(parseInt(appointment.date.split(" ")[1]))
              } 
        
              
            })
       
            setDatesList(datesList.reverse())
        }
     }, [monthlyAppointments])


    useEffect(() => {
        if (year || month || currentDay ) {
            setDateObject(moment().set({'date': currentDay, 'month': month, 'year': year}))
            setSelectedFullDate(moment().set({'date': currentDay, 'month': month, 'year': year}).format("llll"))
            setWrittenDate(`${monthsFull[months.indexOf(month)]}` + ` ` + `${year}`)
            setWrittenMonth(`${monthsFull[months.indexOf(month)]}`)
        }
    }, [year, month, currentDay])



    function setSelectedYear(data) {
        if (year !== data) {
            setYear(data) 
          
            setResult(null)
            setDatesList(null) }
        
    }

    function setSelectedMonth(data) {
       if (writtenMonth !== data) {
           setMonth(data)
           setTimeout(() => {
            setSelectedDate(1)
           
           }, 200);
       
         
        setResult(null) 
        setDatesList(null)
        }
    }

    function setSelectedDate(d) {
        setCurrentDay(d)
        setResult(null)
    }

    function firstDayOfMonth() {
        return dateObject.startOf("month").format("d")
    }

        let blanks = [];
        
        for (let i = 0; i < firstDayOfMonth(); i++) {
          blanks.push(
            <td className="calendar-day empty">{""}</td>
          );
        }

        let daysInMonth = [];

        for (let d = 1; d <= dateObject.daysInMonth(); d++){ 
            let currentday = d == currentDay ? "today" : ""; 
            let booked = datesList && datesList.includes(d) ? "dot" : "nodot";
                daysInMonth.push(  
                   <td key={d} onClick={() => setSelectedDate(d)} className={`calendar-day ${currentday} ${booked}`}>   
                   {d} 
                   </td>);
         }
         
         var totalSlots = [...blanks, ...daysInMonth];
         let rows = [];
         let cells = [];
     
         totalSlots.forEach((row, i) => {
             if (i % 7 !== 0) {
               cells.push(row); // if index not equal 7 that means not go to next week
             } else {
               rows.push(cells); // when reach next week we contain all td in last week to rows 
               cells = []; // empty container 
               cells.push(row); // in current loop we still push current row to new container
             }
             if (i === totalSlots.length - 1) { // when end loop we add remain date
               rows.push(cells);
             }
           });

     
           let calendar = rows.map((d, i) => {

              return <tr>{d}</tr> ;
           });



    
    let allmonths = moment.months()

    function MonthList() {
        

        let months = [];
        allmonths.map(data => {
          months.push(
            <td 
            key={data} 
            className={writtenMonth == data ? "calendar-month today" : "calendar-month"} onClick={() => setSelectedMonth(data)}>
            {data}
            </td>
          );
        });
  
      let monthrows = [];
      let monthcells = [];
  
      months.forEach((monthrow, i) => { 
          if (i % 3 !== 0 || i == 0) { // except zero index 
              monthcells.push(monthrow); 
          } else { 
              monthrows.push(monthcells); 
              monthcells = [];
              monthcells.push(monthrow); 
          }
       });
       monthrows.push(monthcells); // add last row
  
       let monthlist = monthrows.map((r, i) => {
          return <tr className="cal-month-list">
                {Object.keys(r).map((m) => {
                    return (
                    <td className="calendar-month">{r[m]}</td>
                    )
                })}
                </tr>;
       });

       return <div className="abs-list">{monthlist}</div>
    }

    function YearList() {
        let months = [];
        let nextOne = moment()
          .set("year", props)
          .add("year", 2)
          .format("Y");

          function getDates(startDate, stopDate) {
            var dateArray = [];
            var currentDate = moment(startDate);
            var stopDate = moment(stopDate);
            while (currentDate <= stopDate) {
              dateArray.push(moment(currentDate).format("YYYY"));
              currentDate = moment(currentDate).add(1, "year");
            }
            return dateArray;
          }

          let twelveyears = getDates(props, nextOne);

          twelveyears.map(data => {
            months.push(
              <td
                key={data}
                className={year === data ? "calendar-month-2 today" : "calendar-month-2" }
                onClick={() => {
                  setSelectedYear(data);

                }}
              >
                {data}
              </td>
            );
          });
          let rows = [];
          let cells = [];
      
          months.forEach((row, i) => {
            if (i % 3 !== 0 || i == 0) {
              cells.push(row);
            } else {
              rows.push(cells);
              cells = [];
              cells.push(row);
            }
          });
          rows.push(cells);
          let yearlist = rows.map((d, i) => {
            return <tr className="cal-month-list">
                    <td className="calendar-month-2">{d}</td></tr>;
          });

          return <div className="abs-list-2">{yearlist}</div>;
      
        
    }

    function setMonths() {
        monthsOpen ? setMonthsOpen(false) : setMonthsOpen(true)
    }

    function setYears() {
        yearsOpen ? setYearsOpen(false) : setYearsOpen(true)
    }

    function bookDatesList() {

  
      var datesList = []
      monthlyAppointments && monthlyAppointments.forEach((appointment, i) => {
        if (appointment.date.split(" ")[0] === month) {
          datesList.push(parseInt(appointment.date.split(" ")[1]))
        } 

        
      })
      
      setDatesList(datesList.reverse())
    }

    return (
       <div className={showCalendar ? "fade-in front-desk" : "fade-in front-desk-full"}>
        <CourseNav currentUser={currentUser} />
        <Footer selected={3} />

        <div className={showCalendar ? 'left-half' : 'hidden'}>
        {!result ?  <><div className="full-blur"> 
        <Ring className="ring" color="#fff" size={30} /></div></> : null }
        <div className="full-100 c">
                <div className="year-list">
                <div className="scheduled-appt-title wht">
                {year && writtenMonth ?
                <>
                <div onClick={setMonths} className="inline">{monthsOpen ? MonthList() : <div className="abs-list"><td className="calendar-month today">{writtenMonth}&nbsp;&nbsp;<i class="fas fa-sort-down"></i></td></div>}</div>
                <div onClick={setYears} className="inline">{yearsOpen ? YearList() : <div className="abs-list-2"><td className="calendar-month-2 today">{year}&nbsp;&nbsp;<i class="fas fa-sort-down"></i></td></div>}</div>
                </> : null }
                </div>
                </div>
        <div>
     
        <br/>
        <br/>
        </div>
        <table className="calendar-table wht">
            <thead>
              <tr>{weekdayshortname}</tr>
            </thead>
            <tbody>
                {calendar}
            </tbody>
          </table>
          <br/>
          <br/>
        </div> 
   

       </div>

       <div className={showCalendar ? 'right-half' : 'right-half-full'}>
       <div className="scheduled-appt-title moving-gradient">Scheduled Appointments for {month + ` ` + currentDay + `, ` + year}</div>
       <br/>
       
       <div className="chevron-left-top" onClick={setCalendar} >
       <div className={showCalendar ? 'chevron-left-close' : `chevron-left-open`}>
       </div>
       <i class="fa-light fa-arrow-up-left-from-circle"></i>
       </div>
      
        {minMaxDate ?
        <>
        <div className={showCalendar ? "inside-right-half" : "inside-right-half-full"}>

        {artists.map((key) => {

            return (
    
       
        <div className="each-artist">
            <div className='artist-col-name'>
               { key }
            </div>
    
          {result ?
            Object.keys(result).map((keys) => {
                if (result[keys].calendar === key)
                return (
              <div onClick={() => openBookingPage(result[keys])} className={result[keys].type.includes("PACKAGE") ? `appointment-box package box-${result[keys].duration} appointment-${result[keys].time.split(":").join("")}` : result[keys].type.includes("Brows") ? `appointment-box brows box-${result[keys].duration} appointment-${result[keys].time.split(":").join("")}` : result[keys].type.includes("Lips") ? `appointment-box lips box-${result[keys].duration} appointment-${result[keys].time.split(":").join("")}` : result[keys].category.includes("STUDENTS") ? `appointment-box students box-${result[keys].duration} appointment-${result[keys].time.split(":").join("")}` : result[keys].type.includes("Lash") ? `appointment-box eyeliner box-${result[keys].duration} appointment-${result[keys].time.split(":").join("")}` : `appointment-box other box-${result[keys].duration} appointment-${result[keys].time.split(":")[0] + result[keys].time.split(":")[1]}`  } key={keys}>
              <div className="today-time">
                {result[keys].time } - {result[keys].endTime }
              </div>
              <div className="today-type">
                {result[keys].type}
              </div>
              <div className="today-name">
                {result[keys].firstName +` ` + result[keys].lastName}
              </div>
              {result[keys].forms ?
             Object.keys(result[keys].forms).map((keys2)  => {
                var forms = result[keys].forms
                if (forms[keys2].name.includes("Medical Form"))
              
                    return (
                        <div className='medical-form-overall'>
                            
                          
                            {forms[keys2].values[0] && !forms[keys2].values[0].value.includes("none") ?
                            forms[keys2].values[0].value : null }
                        
                            {forms[keys2].values[1] && !forms[keys2].values[1].value.includes("none") ?
                            forms[keys2].values[1].value : null }
                        
                        </div>
                    )
                })
            
              : null}
            { result[keys].notes ? 
              <div className="today-notes">
                {result[keys].notes}
              </div> : null }
              <div className="today-type2">
              Price: <span className=''>${parseInt(result[keys].priceSold)}.00</span>
              </div>
                <div className="today-type2">
                Amount Paid: <span className=''>${parseInt(result[keys].amountPaid)}.00</span>
       
              </div>
              <div className="today-due">
                Balance Due: <span className='today-name'>${parseInt(result[keys].priceSold) - parseInt(result[keys].amountPaid)}.00</span>
              </div>





              </div>
                )
                })
          : null}
          
          </div> 
            )})}
          {result && result.length == 0 ? <div className="appointment-box">
          
          <div className="today-type c">
          There are no scheduled appointments.
              </div></div> : null}
  
          </div>
          
          </> : null }
 
       </div>
       {monthsOpen || yearsOpen ? <div className="full-blur"></div> : null }
       {bookingPage ? 
          <BookingPage client={chosenClient} closeTouchup={() => window.location.reload()} /> : null}
       </div>
    )
}