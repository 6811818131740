import React, {useState, useEffect } from 'react';
import {Link} from 'react-router-dom';

import Footer from './footer';
import CourseNav from './course_nav_container';
import CreateTask from './create_task';


export default function Tasks(props){

    const {logout, currentUser, postTask, fetchTasks, newTask, deletedTask, deleteTask, tasks,
       } = props
    const [taskPage, setTaskPage] = useState(false)
    const [artistName, setArtistName] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchTasks()
 
     }, [])


  
  
     useEffect(() => {
      if (newTask)
      fetchTasks()
   }, [newTask])
  
   useEffect(() => {
    if (deletedTask)
    fetchTasks()
  }, [deletedTask])
  
   function removeTask(task_id) {
    deleteTask(task_id)
    fetchTasks()
   }

   function openTaskPage() {
    taskPage ? setTaskPage(false) : setTaskPage(true)
  }

    return (
        
        <div className="fade-in">
        <CourseNav/>
       
        <Footer selected={2} currentUser={currentUser.calendarID}/>

        <div className="main-page full-100 c wht">


        <div className='disclaimer'>
       Need to block off a day, schedule a third session for a client, or ask about something in your chart? Submit a task or request here.
        </div>
        <br/>
        <div className='task-list'>
        <div className="scheduled-appt-title wht fxd">
          My Tasks + Schedule Requests
          <span>

            <button onClick={openTaskPage} className='add-btn'>
            <div className='inner-add-btn'>
            {taskPage ? `-` : `+`}
            </div>
            </button>
          </span>
        </div>
        {taskPage ?
        <CreateTask close={openTaskPage} postTask={postTask} currentUser={currentUser.first_name}/> : null}
        <div className='inner-task-list'>
         {tasks && Object.keys(tasks).reverse().map((key) => {
          if (currentUser && tasks[key].submittedBy === currentUser.first_name)
          return (
            <div className='task-box'>
        
            <div className='submitted-by'>
              Task by {tasks[key].submittedBy}
            </div>
                        <div className='submitted-by date'>
            {tasks[key].createdAt.split("T")[0].split("-")[1]}/{ tasks[key].createdAt.split("T")[0].split("-")[2]}/{tasks[key].createdAt.split("T")[0].split("-")[0]}
              </div>
      
      
              <div className='submitted-by-body'>
              {tasks[key].body}
              </div>
              <div className='task-btn-row'>
                <button onClick={() => removeTask(tasks[key]._id)} className='task-btn'>
                <i class="fa-light fa-trash-can"></i>
                </button>
                </div>
              </div>
         )
         })} 
        
        </div></div>

        </div>    </div>
        

        
    )
    }