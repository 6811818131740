import axios from 'axios';


export const updateUserMicrobladingSessionsCompleted = (id, update) => {
  return axios.patch(`api/users/${id}`, update)
}

export const fetchAppointments = (calendarID, minDate, maxDate) => {
  return axios.get(`api/users/appointments/${calendarID}/${minDate}/${maxDate}`)
}

export const fetchAppointments2 = (calendarID, minDate, maxDate) => {
  return axios.get(`api/users/appointments/${calendarID}/${minDate}/${maxDate}`)
}

export const fetchAvailableDates = (calendarID, apptid, month) => {
  return axios.get(`api/users/availability/${calendarID}/${apptid}/${month}`)
}

export const fetchAvailableTimes = (calendarID, apptid, date) => {
  return axios.get(`api/users/availabiletimes/${calendarID}/${apptid}/${date}`)
}

export const postAppointment = (apptDetails) => {
  return axios.post(`api/users/appointment`, apptDetails)
}

