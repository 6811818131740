import React from 'react';
import { AuthRoute, ProtectedRoute } from '../util/route_util';
import { Switch } from 'react-router-dom';
import NavBarContainer from './nav/navbar_container';
import './App.css';
import './Course.css'
import TweetsContainer from './tweets/tweets_container';
import MainPage from './main/main_page';
import LoginContainer from './session/login_container';
import CreateContainer from './session/create_container';
import ProfileContainer from './profile/profile_container';
import TweetComposeContainer from './tweets/tweet_compose_container';
import DashboardContainer from './dashboard/dashboard_container';
import course_dash_container from './course/course_dash_container';
import Checkout from './payments/payment_container';
import Info from './course/info';
import Register from './registration/registration_container'
import CourseInfo from './dashboard/course-info';
import Footer from './dashboard/footer';
import Submissions from './dashboard/submissions_container';
import Welcome from './dashboard/welcome_container'
import Contact from './dashboard/contact'
import './dashboard/appointments.css'
import schedule from './dashboard/schedule_container';
import booking_page_container from './dashboard/booking_page_container';
import Frontdesk from './dashboard/frontdesk_container';
import Tasks from './dashboard/tasks_container.js';

const App = () => (
  <div className="main">
    <Switch>
      <AuthRoute exact path="/" component={MainPage} />
      <AuthRoute exact path="/login" component={LoginContainer} />
      <AuthRoute exact path="/signup" component={CreateContainer} />
      <AuthRoute exact path="/register" component={Register} />
      <ProtectedRoute exact path="/checkout" component={Checkout} />
      <ProtectedRoute exact path="/frontdesk" component={Frontdesk} />
      <ProtectedRoute exact path="/dashboard" component={Welcome} />
      <ProtectedRoute exact path="/mytasks" component={Tasks} />
      <ProtectedRoute exact path="/schedule" component={schedule} />
      <ProtectedRoute exact path="/contact" component={Contact} />
      <ProtectedRoute exact path="/course-info" component={CourseInfo} />
      <ProtectedRoute exact path="/bookingpage" component={booking_page_container} />
      <ProtectedRoute exact path="/dashboard/:name" component={course_dash_container} />
      <ProtectedRoute exact path="/profile" component={ProfileContainer} />
      <ProtectedRoute exact path="/new_tweet" component={TweetComposeContainer} />
    </Switch>
  </div>
);

export default App;