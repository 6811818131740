import React from 'react';
import {Link} from 'react-router-dom';

class Footer extends React.Component {
    constructor(props){
        super(props);
        this.state = { hidden: 'yes',
        prevScrollpos: window.pageYOffset,
        visible: false}

        this.handleScroll = this.handleScroll.bind(this)
    }
    
    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
      }
      
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
      }


      handleScroll = () => {
      
        const currentScrollPos = window.pageYOffset;
        const visible = currentScrollPos > 0;
      
        this.setState({
          visible
        });
      };

    render() {


        return (
            <div className="course-footer">
            <div className="mobile-nav-container">
            <div className={this.state.visible ? "mobile-navbar nav-black" : "mobile-navbar nav-black"}>

                <Link className={this.props.selected === 1 ? "mobile-navbar-link-purp" : "mobile-navbar-link"} to="/dashboard"><i class="fad fa-home-lg-alt"></i><br/>Home</Link>
                

                <Link className={this.props.selected === 3 ? "mobile-navbar-link-purp" : "mobile-navbar-link"} to="/schedule"><i class="fad fa-calendar"></i><br/>My Schedule</Link>
   
                <Link className={this.props.selected === 2 ? "mobile-navbar-link-purp" : "mobile-navbar-link"} to="/mytasks"><i class="fa-duotone fa-list-check"></i><br/>My Tasks</Link>
            </div>
            </div>
            </div>
        )
    }
}

export default Footer;