import React, {useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import Logo from '../../assets/cosmetinkLogo.png'
import Footer from './footer';
import CourseNav from './course_nav_container';
import { fetchAppointments } from '../../util/users_util';
import BookingPage from './booking_page_container';
import { Ring } from 'react-spinners-css';
import Logo1 from '../../assets/costranslogo.png'
import Logo2 from '../../assets/costranslogo2.png'


export default function Welcome(props) {
    const { currentUser } = props
    const [profile, setProfile] = useState(null)
    const [result, setResult] = useState(null)
    const [upcoming, setUpcoming] = useState(null)
    const [touchup, setTouchup] = useState(false)
    const [chosenClient, setChosenClient] = useState(null)
    const [touchupIDs, setTouchupIDs] = useState([])
    const [selectedArtist, setSelectedArtist] = useState(null)
    const artists = ["Sean", "Nikky", "Karlee"]

    const date = new Date()
    const today = `${date.getFullYear()}`+`-`+`${date.getMonth() + 1}`+`-`+`${date.getDate()}`
    const thisWeek = `${date.getFullYear()}`+`-`+`${date.getMonth() + 1}`+`-`+`${date.getDate() + 6}`
    const months = [`January`, `February`, `March`, `April`, `May`, `June`, `July`, `August`, `September`, `October`, `November`, `December`]
    const writtenDate = `${months[date.getMonth()]}` + ` ` + `${date.getDate()}` + `, ` + `${date.getFullYear()}`


    useEffect(() => {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
  }, [])

    function openProfile() {
        profile ? setProfile(null) : setProfile(true)
    }

    async function getAppointments() {
        const response = await fetchAppointments(currentUser.calendarID, today, today)
         setResult(response.data.reverse())
    }

    async function getWeekAppointments() {
      const response = await fetchAppointments(currentUser.calendarID, today, thisWeek)
       setUpcoming(response.data.reverse())
  }

    var getDaysInMonth = function(month,year) {
     return new Date(year, month, 0).getDate();
    };
  
    useEffect(() => {
      getAppointments()
      getWeekAppointments()
  }, [])



  function selectArtist(artist) {
    if (artist) {
    if (selectedArtist !== artist) { setSelectedArtist(artist) } }
    else { setSelectedArtist(null) }
  }

  function bookTouchUp(result) {
    if (touchup) {
      setTouchup(false)
      setChosenClient(null) } 
    else { 
      setTouchup(true)
      setChosenClient(result)
    }
  }


    return (
      <div className="fade-in">
          <CourseNav/>
          <div className="main-page">
          <Footer selected={1} currentUser={currentUser.calendarID}/>
          {!result ? <><div className="full-blur"> 
          <Ring className="ring" color="#fff" size={30} /></div></> : null }

          <div className="">
          <div className="entry-text-p l pad-10 moving-gradient">{currentUser.first_name}'s Appointments for Today.</div>
          <div className="scheduled-appt-title moving-gradient l">{writtenDate}</div>          
          { result ? <>
            {result.length < 1 ? 
              <div className="appointment-box">
              <div className="today-type c wht">
              There are no scheduled appointments today.
              </div>
   
              </div> : null}

            
            {!currentUser.calendarID && !selectedArtist ? 
            <>
            {artists.map((artist) => {
              return (
             <div onClick={() => selectArtist(artist)} className={result.type.includes("PACKAGE") ? `appointment-box package box-${result.duration} appointment-${result.time.split(":").join("")}` : result.type.includes("Brows") ? `appointment-box brows box-${result.duraction} appointment-${result.time.split(":").join("")}` : result.type.includes("Lips") ? `appointment-box lips box-${result.duration} appointment-${result.time.split(":").join("")}` : artist.category.includes("STUDENTS") ? `appointment-box students box-${result.duration} appointment-${result.time.split(":").join("")}` : result.type.includes("Lash") ? `appointment-box eyeliner box-${result.duration} appointment-${result.time.split(":").join("")}` : `appointment-box other box-${result.duration} appointment-${result.time.split(":")[0] + result.time.split(":")[1]}`} ><div className="today-time">{ artist }</div> </div>)
            })}</> :
            !currentUser.calendarID && selectedArtist ? 
            <>
            <div onClick={() => selectArtist(null)} className="appointment-box pnk-wrap"><div className="today-time">{ selectedArtist }</div></div>
            </> : null }

            {selectedArtist ? 
            <>
              {result ? Object.keys(result).map((keys) => {
              const client = result[keys]
              if (client.calendar == selectedArtist) {
              return (
              <div className="appointment-box" key={keys}>
              {!currentUser.calendarID ? <div className="today-time moving-gradient">{result[keys].calendar}</div> : null }
              <br/>
              <div className="today-time">
                {result[keys].time}
              </div> 
              <div className="today-type">
                {result[keys].type}
              </div>
              <div className="today-name">
                {result[keys].firstName +` ` + result[keys].lastName}
              </div>

              {result[keys].notes ? 
              <div className="today-notes">
                {result[keys].notes}
              </div> : null }
  
              {currentUser ?
                <a href={`mailto:cosmetinkphotos@gmail.com?subject=${currentUser.first_name + ` ` + currentUser.last_name + `  -  ` + result[keys].firstName +` ` + result[keys].lastName}&body=`} className="assignment-submission"><span className=""></span>Send Before & After Photos<br/></a>
                    : null }
              <div className="today-due">
                Balance Due: ${parseInt(result[keys].priceSold) - parseInt(result[keys].amountPaid)}.00
              </div>
              </div>) }
            }): null} 
            </> : null}

            {currentUser.calendarID ?
            <>
            {result ? Object.keys(result).map((keys) => {
              const client = result[keys]
              return (
              <div className={result[keys].type.includes("PACKAGE") ? `appointment-box package` : result[keys].type.includes("Brows") ? `appointment-box brows` : result[keys].type.includes("Lips") ? `appointment-box lips` : result[keys].category.includes("STUDENTS") ? `appointment-box students box-${result[keys].duration} appointment-${result[keys].time.split(":").join("")}` : result[keys].type.includes("Lash") ? `appointment-box eyeliner` : `appointment-box other` } key={keys}>
              {!currentUser.calendarID ? <div className="today-time moving-gradient">{result[keys].calendar}</div> : null }
            
              <div className="today-time">
                {result[keys].time} - {result[keys].endTime}
              </div> 
              <div className="today-type">
                {result[keys].type}
              </div>
              <div className="today-name">
                {result[keys].firstName +` ` + result[keys].lastName}
              </div>

              {result[keys].notes ? 
              <div className="today-notes">
                {result[keys].notes}
              </div> : null }
     
              {currentUser ?
                <a href={`mailto:cosmetinkphotos@gmail.com?subject=${currentUser.first_name + ` ` + currentUser.last_name + `  -  ` + result[keys].firstName +` ` + result[keys].lastName}&body=`} className="assignment-submission"><span className=""></span>Send Before & After Photos<br/></a>
                    : null }
              <div className="today-due">
                Balance Due: ${parseInt(result[keys].priceSold) - parseInt(result[keys].amountPaid)}.00
              </div>
              </div>)
            }): null} </> : null }

                

            </> : <><br/><br/>There are no scheduled appointments today.</>}
          </div>
          </div>
          {touchup ? 
          <BookingPage client={chosenClient} closeTouchup={bookTouchUp} /> : null}
          

 
          </div>
        


 


    );
    }