import { RECEIVE_USER_INFO, RECEIVE_UPDATED_USER_INFO, RECEIVE_APPOINTMENTS, RECEIVE_AVAILABILITY, RECEIVE_AVAILABLE_TIMES,
         RECEIVE_NEW_APPOINTMENT, RECEIVE_MONTHLY_APPOINTMENTS, RECEIVE_ARTIST_INFO } from '../actions/user_actions';
  
  const UserReducer = (state = { user: undefined, updated: undefined, appointments: undefined, availability: undefined, times: undefined, newappointment: undefined, monthlyAppointments: undefined }, action) => {
    Object.freeze(state);
    let newState = Object.assign({}, state);
    switch(action.type) {
      case RECEIVE_USER_INFO:
        newState.user = action.user.data[0];
        return newState;
        case RECEIVE_ARTIST_INFO:
          newState.artist = action.artist.data[0];
          return newState; 
      case RECEIVE_UPDATED_USER_INFO:
        newState.updated = action.updated.data
        return newState;
      case RECEIVE_APPOINTMENTS:
        newState.appointments = action.appointments.data
        return newState;
      case RECEIVE_AVAILABILITY:
        newState.availability = action.availability.data
        return newState;
      case RECEIVE_AVAILABLE_TIMES:
        newState.times = action.times.data
        return newState;
      case RECEIVE_NEW_APPOINTMENT:
        newState.newappointment = action.res.data
        return newState;
      case RECEIVE_MONTHLY_APPOINTMENTS:
        newState.monthlyAppointments = action.monthlyAppointments.data
        return newState;
      default:
        return state;
    }
  };
  
  export default UserReducer;