import { connect } from 'react-redux';
import Tasks from './tasks';
import { fetchTasks, postTask, deleteTask } from '../../actions/task_actions';

const mapStateToProps = (state) => {
  return {
    currentUser: state.session.user,
    tasks: state.tasks.tasks,
    newTask: state.tasks.newTask,
    deletedTask: state.tasks.deletedTask
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTasks: () => dispatch(fetchTasks()),
        postTask: (taskBody) => dispatch(postTask(taskBody)),
        deleteTask: (task_id) => dispatch(deleteTask(task_id))
    }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tasks);